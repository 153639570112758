import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bridgesLogo from "../../assets/bridges.png";
import loginImage from "../../assets/login-image.png";
import { useAuth } from "../../context/AuthContext";
import api from "../../service/api";
import {
  Description,
  Input,
  InputTitle,
  InputWrapper,
  RecoveryLabel,
  Title,
  WrapperMid,
  WrapperLogin,
  WrapperPage,
  WrapperRecovery,
  WrapperRight,
  WrapperInputIcon,
  WrapperImg,
} from "./style";
import { Checkbox, FormControlLabel, Button, CircularProgress } from "@mui/material";
import { RectangularButton } from "../../components/RectangularButton";

interface LoginResponse {
  tokens: {
    access: string;
  };
}

export const Login: React.FC = () => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [mail, setMail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode<any>(token);
      navigate(tokenData.groups[0] === "financeiro" ? "/wallet" : "/today");
      setIsAuthenticated(true);
    }
  }, [navigate, setIsAuthenticated]);

  const handleVisiblePassword = () => {
    setIsVisiblePassword(!isVisiblePassword);
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password: string): boolean => {
    return password.length >= 8;
  };

  const handleLogin = () => {
    // if (!validateEmail(mail)) {
    //   toast.error("Por favor, insira um e-mail válido");
    //   return;
    // }

    // if (!validatePassword(password)) {
    //   toast.error("Senha deve ter pelo menos 8 caracteres");
    //   return;
    // }

    const data = {
      username: mail,
      password: password,
    };

    setIsLoading(true);
    api
      .post<LoginResponse>("/login/", data)
      .then((resp) => {
        localStorage.setItem("bridges.token", resp.data.tokens.access);
        if (rememberMe) {
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("rememberMe");
        }
        const tokenData = jwtDecode<any>(resp.data.tokens.access);

        const targetRoute = tokenData.groups[0] === "financeiro" ? "/wallet" : "/today";
        window.location.href = targetRoute;

        setIsAuthenticated(true);

        localStorage.setItem("userName", tokenData.name || "Usuário");
        localStorage.setItem("userPhoto", tokenData.professional_photo || "");
      })
      .catch(() => {
        toast.error("E-mail ou senha incorretos");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <WrapperPage>
      <WrapperMid>
        <WrapperLogin>
          <WrapperImg>
            <img src={bridgesLogo} alt="Bridges Logo" />
          </WrapperImg>
          <Title>Bem-vindo de volta!</Title>
          <Description>Por favor, insira suas credenciais para acessar sua conta.</Description>
          <InputTitle>E-mail</InputTitle>
          <InputWrapper>
            <Input
              placeholder="Digite seu e-mail"
              value={mail}
              autoComplete="off"
              onChange={(e) => setMail(e.target.value)}
              defaultValue={localStorage.getItem("rememberMeEmail") || ''}
            />
          </InputWrapper>
          <InputTitle style={{ marginTop: 45 }}>Senha</InputTitle>
          <InputWrapper>
            <WrapperInputIcon>
              <Input
                placeholder="Digite sua senha"
                type={isVisiblePassword ? "text" : "password"}
                value={password}
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              {isVisiblePassword ? (
                <FiEyeOff onClick={handleVisiblePassword} />
              ) : (
                <FiEye onClick={handleVisiblePassword} />
              )}
            </WrapperInputIcon>
          </InputWrapper>
          <WrapperRecovery>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <span style={{ whiteSpace: 'nowrap' }}>Lembrar de mim</span>
              }
            />
            <RecoveryLabel onClick={() => navigate("/recovery-password")}>Esqueceu a senha?</RecoveryLabel>
          </WrapperRecovery>
          <RectangularButton
            onClick={handleLogin}
            disabled={isLoading || !mail || !password}
            text="Entrar"
          />
        </WrapperLogin>
        <WrapperRight>
          <img src={loginImage} alt="Login" />
        </WrapperRight>
      </WrapperMid>
    </WrapperPage>
  );
};
