import { Title, Subtitle, Info, AlertIcons, InfoLabelWrapper, SubtitleWrapper } from "./style";
import React from 'react';

interface InfoLabelProps {
    label: string;
    value: string;
    alert?: boolean;
    tagName?: string;
    tagColor?: string;
}

export const InfoPaciente: React.FC<InfoLabelProps> = ({ label, value, alert, tagName, tagColor }) => {
    return (
        <InfoLabelWrapper>
            <Title>{label}</Title>
            <SubtitleWrapper>
                <Subtitle>{value}</Subtitle>
                {tagName && <Info color={tagColor || '#767676'}>{tagName}</Info>}
            </SubtitleWrapper>
        </InfoLabelWrapper>
    );
};
