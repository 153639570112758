import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex; /* Usando flexbox para alinhar ícone e texto */
  align-items: center; /* Centraliza ícone e texto verticalmente */
  justify-content: center; /* Centraliza conteúdo horizontalmente */
  background-color: #1270FC;
  color: white;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 8px; /* Espaço entre o ícone e o texto */
    font-size: 16px; /* Ajuste do tamanho do ícone */
  }
`;
