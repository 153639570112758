// SearchBar.js
import React from "react";
import { SearchInputWrapper, SearchIcon, SearchInput } from "./style";
import { MdSearch } from "react-icons/md";

interface CustomSearchProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSearch: React.FC<CustomSearchProps> = ({ onChange }) => {
  return (
    <SearchInputWrapper>
      <SearchInput type="text" placeholder="Pesquisar" onChange={onChange} />
      <SearchIcon>
        <MdSearch size={20} />
      </SearchIcon>
    </SearchInputWrapper>
  );
};

export default CustomSearch;
