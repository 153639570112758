import styled from "styled-components";

const TagsPaymentType = {
    default: {
        color: '#919EAB',
        backgroundColor: '#F4F6F8',
    },
    paid: {
        color: '#0BA900',
        backgroundColor: '#00D34645',
    },
    pending: {
        color: '#DFBA00',
        backgroundColor: '#FFD40066',
    },
    opened: {
        color: '#1270FC',
        backgroundColor: '#1270FC66'
    }
}

export interface TagsProps {
    type: keyof typeof TagsPaymentType;
    text?: string;
}


export const TagsWrapper = styled.div<TagsProps>`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50px;
    border: 1px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
`

export const Ball = styled.div<TagsProps>`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ type }) => TagsPaymentType[type].color};
    margin-right: 8px;
`

export const TagText = styled.span<TagsProps>`
    font-size: 14px;
    line-height: 16px;
    color: ${({ type }) => TagsPaymentType[type].color};
` 