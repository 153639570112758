import React from "react";
import { StyledButton } from "./styles";
import { FaCalendarDay, FaClock } from "react-icons/fa";

interface ToggleButtonProps {
  isYesterday: boolean;
  onClick: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isYesterday, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      {isYesterday ? (
        <>
          <FaCalendarDay style={{ marginRight: "8px" }} />
          Consultar Hoje
        </>
      ) : (
        <>
          <FaClock style={{ marginRight: "8px" }} />
          Consultar Ontem
        </>
      )}
    </StyledButton>
  );
};

export default ToggleButton;
