import React, { useEffect, useState } from "react";
import {
  HeaderWrapper,
  InputsContainer,
  ProfileContainer,
  UserImage,
  UserName,
  UserProfile,
  WelcomeText,
} from "./style";
import CustomSearch from "../CustomSearch";
import CustomDropDown from "../CustomDropDown";
import { useLocation } from "react-router-dom";
import ToggleButton from "./Components/ToggleButton/ToggleButton";

interface HeaderProps {
  onSearchChange: (value: string) => void;
  onSelectChange: (value: string) => void;
  options: string[];
  onYesterdayClick: () => void;
  onTodayClick: () => void;
}

const Header: React.FC<HeaderProps> = ({
  onSearchChange,
  onSelectChange,
  options,
  onYesterdayClick,
  onTodayClick,
}) => {
  const location = useLocation();
  const [isYesterday, setIsYesterday] = useState(false);
  const [userName, setUserName] = useState<string>("Usuário");
  const [userPhoto, setUserPhoto] = useState<string>(require("../../../assets/user-profile.png"));

  useEffect(() => {
    const storedName = localStorage.getItem("userName");
    const storedPhoto = localStorage.getItem("userPhoto");

    if (storedName) setUserName(storedName);
    if (storedPhoto) setUserPhoto("https://dev.api.bridges.conjosa.com.br" + storedPhoto);
  }, []);

  const handleToggleClick = () => {
    if (isYesterday) {
      onTodayClick();
    } else {
      onYesterdayClick();
    }
    setIsYesterday(!isYesterday);
  };

  const renderInputsForToday = () => {
    if (location.pathname !== "/today") return null;

    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <ToggleButton isYesterday={isYesterday} onClick={handleToggleClick} />
      </>
    );
  };

  return (
    <HeaderWrapper>
      <InputsContainer>{renderInputsForToday()}</InputsContainer>
      <ProfileContainer>
        <UserProfile>
          <UserName>
            {userName}
            <WelcomeText>Seja bem-vindo!</WelcomeText>
          </UserName>
        </UserProfile>
        <UserImage src={userPhoto} alt="Perfil" />
      </ProfileContainer>
    </HeaderWrapper>
  );
};

export default Header;
