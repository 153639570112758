// UserSelect.js
import React from "react";
import { SelectWrapper, UserSelector, DropDownIcon } from "./style";
import { MdKeyboardArrowDown } from "react-icons/md";

interface CustomDropDownProps {
  options?: string[]; // Opções recebidas como prop, opcional
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({ options = [], onChange }) => {
  return (
    <SelectWrapper>
      <UserSelector onChange={onChange}>
        <option value="Todos">Todos</option> {/* Opção padrão */}
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </UserSelector>
      <DropDownIcon>
        <MdKeyboardArrowDown size={20} />
      </DropDownIcon>
    </SelectWrapper>
  );
};

export default CustomDropDown;
