import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const PaymentList = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex: 1;
`

export const PaymentContainer = styled.div`
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 0.2px solid #d3d3d3;
    border-radius: 10px;
    width: 322px;
    height: 170px;
`

export const PaymentHeaderTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 3px;
`

export const PaymentContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    margin: '0 3px'
`

export const PaymentWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px 0 15px;
    margin-top: 3px;
`

export const PaymentLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
`

export const PaymentLabelReceipt = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`

interface LabelProps {
    color?: string;
    paid?: boolean;
}

export const PaymentLabel = styled.span<LabelProps>`
    font-size: 16px;
    text-decoration: ${props => props.paid ? 'line-through' : 'none'};
    font-style: normal;
    font-weight: 500;
    color: ${props => props.color || '#000'};
`

interface InfoProps {
    bold?: boolean;
    color?: string;
    paid?: boolean;
}

export const PaymentInfo = styled.span<InfoProps>`
    font-size: 16px;
    font-style: normal;
    text-decoration: ${props => props.paid ? 'line-through' : 'none'};
    font-weight: ${props => props.bold ? 700 : 400};
    color: ${props => props.color || '#000'} 
`

export const PaymentInfoDate = styled.span`
    font-size: 12px;
    font-style: normal;
`

const TagsPaymentType = {
    default: {
        color: '#919EAB',
        backgroundColor: '#F4F6F8',
    },
    paid: {
        color: '#118D57',
        backgroundColor: '#D3FCD2',
    },
    prepaid: {
        color: '#1486CC',
        backgroundColor: '#4DBCFF',
    },
    pending: {
        color: '#B76E00',
        backgroundColor: '#FFF5CC',
    }
}

export interface DolarIconProps {
    type: keyof typeof TagsPaymentType;
}

export const DollarIcon = styled.span<DolarIconProps>`
    width: 43px;
    height: 43px;
    color: ${({ type }) => type == 'prepaid' ? 'white' : TagsPaymentType[type].color};
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    right: -12px;
    top: -13px;
    border: 1px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`