import React from 'react';
import { InfoLabelWrapper, ProfileImage, Subtitle } from './style';

interface InfoLabelProps {
    value: string;
    profileImage: string;
}

export const InfoProfissional: React.FC<InfoLabelProps> = ({ profileImage, value }) => {
    const defaultImage = require("../../../../assets/user-profile.png");

    return (
        <InfoLabelWrapper>
            <ProfileImage
                src={profileImage}
                alt="Perfil"
                onError={(e) => {
                    (e.currentTarget as HTMLImageElement).src = defaultImage;
                }}
            />
            <Subtitle>{value}</Subtitle>
        </InfoLabelWrapper>
    );
};
