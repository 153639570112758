import { useEffect, useState } from "react";
import { customStyles } from "../../../components/Shared";
import { Tag } from "../../../components/Tags";
import formatCurrency from "../../../utils/formatCurrencyBr";
import {
    AverageInfoWrapper,
    DisplayBlock,
    EndWrapper,
    ExamsHeader,
    ExamsListItem,
    ExamsListWrapper,
    IconContainer,
    InfoWrapper,
    InitialAndEndTimeWrapper,
    InitialWrapper,
    ExamIcon
} from "./style";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { HeaderTitle, HeaderWrapper } from "../../Patients/Profile/style";
import { IoCloseOutline } from "react-icons/io5";
import { InfoPaciente } from "./InfoPaciente";
import { InfoProfissional } from "./InfoProfissional";
import { CheckIcon } from "./CheckIcon";
import { TimeBox } from "./TimeBox";
interface ProfessionalInfos {
    ctime: Date;
    appointment?: any;
    paymentStatusClicked?: () => void;
    clientClicked?: () => void;
    appointmentClicked?: () => void;
    prontoClicked?: () => void;
    prontoLoading?: boolean;
}

export const ProfessionalInfos = ({ ctime, appointment, paymentStatusClicked, clientClicked, appointmentClicked, prontoClicked, prontoLoading }: ProfessionalInfos) => {
    const [modaDetaillIsOpen, setModaDetaillIsOpen] = useState(false)
    const [appointmentClickedState, setAppointmentClickedState] = useState(false)
    const [examsClickedState, setExamsClickedState] = useState(false)

    useEffect(() => {
        if (examsClickedState && appointmentClickedState)
            openExamsList()
        else
            if (appointmentClickedState)
                appointmentClicked?.()

        setAppointmentClickedState(false)
        setExamsClickedState(false)
    }, [appointmentClickedState, examsClickedState])

    const fadeDetail = useSpring({
        from: { opacity: 0 },
        to: { opacity: modaDetaillIsOpen ? 1 : 0 },
    });

    const openExamsList = () => {
        setModaDetaillIsOpen(true)
    }

    return (
        <>
            <Modal
                isOpen={modaDetaillIsOpen}
                onRequestClose={() => setModaDetaillIsOpen(false)}
                shouldCloseOnOverlayClick={true}
                style={customStyles}
            >
                <animated.div style={fadeDetail}>
                    <ExamsHeader>
                        <HeaderWrapper>
                            <HeaderTitle>Exames</HeaderTitle>
                            <IoCloseOutline
                                size={22}
                                onClick={() => setModaDetaillIsOpen(false)}
                                color="#919EAB"
                                cursor={"pointer"}
                            />
                        </HeaderWrapper>

                        <ExamsListWrapper>
                            {
                                appointment?.exams.map((exam: any, index: number) => {
                                    return (
                                        <ExamsListItem key={index} isEven={index % 2 == 0}>
                                            {exam.name}
                                        </ExamsListItem>
                                    )
                                })
                            }
                        </ExamsListWrapper>
                    </ExamsHeader>
                </animated.div>
            </Modal>

            {/* <HourAndValueWrapper>
                <Hour color={appointment.status.color}>{appointment?.start.split(/[-T:]/)[3] + ':' + appointment?.start.split(/[-T:]/)[4]}</Hour>
                <Value>
                    <InfoLabel label="Valor" value={formatCurrency((appointment.service.price * 100 || '000').toString())} />
                </Value>
                <CustomButton onClick={() => prontoClicked?.()} theme="pacientePronto" disabled={prontoLoading}>
                    Pronto
                </CustomButton>
            </HourAndValueWrapper> */}

            <InfoWrapper>
                <AverageInfoWrapper hasExams={appointment?.exams.length > 0} style={{ paddingBottom: '4px' }}>
                    <DisplayBlock onClick={() => clientClicked?.()}>
                        <InfoPaciente
                            label="Paciente"
                            value={appointment?.paciente}
                            alert={appointment?.patient?.has_pendency}
                            tagName={appointment?.patient.tag?.name || 'Normal'}
                            tagColor={appointment?.patient.tag?.color}
                        />
                    </DisplayBlock>
                    <DisplayBlock onClick={() => clientClicked?.()}>
                        <TimeBox color={
                            appointment?.status.name === 'Pago'
                                ? 'green'
                                : appointment?.status.name === 'Pagamento Parcial'
                                    ? 'blue'
                                    : 'yellow'
                        } time={appointment?.start.split(/[-T:]/)[3] + ':' + appointment?.start.split(/[-T:]/)[4]} showIcon={appointment?.patient?.has_pendency} />
                    </DisplayBlock>
                </AverageInfoWrapper>
                {appointment?.exams.length > 0 && (
                    <DisplayBlock>
                        <InfoPaciente label="Exames" value="" />
                        {appointment.exams.map((exam: any) => (
                            <ExamIcon data-name={exam.name}>
                                {exam.name[0].toUpperCase()}
                            </ExamIcon>
                        ))}
                    </DisplayBlock>
                )}

                <InitialAndEndTimeWrapper>
                    <InitialWrapper hasExams={appointment?.exams.length > 0} style={{ position: 'relative' }}>
                        <IconContainer hasExams={appointment?.exams.length > 0} onClick={() => prontoClicked?.()} >
                            <CheckIcon
                                color={
                                    appointment?.status.name === 'Pago'
                                        ? 'green'
                                        : appointment?.status.name === 'Pagamento Parcial'
                                            ? 'blue'
                                            : 'yellow'
                                }
                            />
                        </IconContainer>
                    </InitialWrapper>
                    <EndWrapper hasExams={appointment?.exams.length > 0}></EndWrapper>
                    <AverageInfoWrapper hasExams={appointment?.exams.length > 0} onClick={() => appointmentClicked?.()}>
                        <InfoProfissional value={appointment.professional.name} profileImage={appointment.professional.photo || require("../../../assets/user.png")} />
                    </AverageInfoWrapper>
                </InitialAndEndTimeWrapper>

                <AverageInfoWrapper hasExams={appointment?.exams.length > 0} style={{ alignItems: 'center' }} onClick={() => paymentStatusClicked?.()}>
                    <DisplayBlock>
                        <InfoPaciente
                            label="Valor"
                            value={formatCurrency((appointment.service.price * 100 || '000').toString())}
                        />
                    </DisplayBlock>
                    <DisplayBlock>
                        <InfoPaciente
                            label="Consulta"
                            value={appointment?.service.name.includes("Consulta")
                                ? appointment?.service.name.replace("Consulta", "").trim()
                                : appointment?.service.name}
                        />
                    </DisplayBlock>
                    <DisplayBlock>
                        <Tag
                            type={appointment?.status.name == "Pago" ? "paid" : "pending"}
                            text={appointment?.status.name == "Pago" ? "Pago" : appointment?.status.name == "Pagamento Parcial" ? 'Parcial' : 'Em aberto'}
                        />
                    </DisplayBlock>
                </AverageInfoWrapper>
            </InfoWrapper>
        </>
    );
};
