import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  height: 100px;
  box-sizing: border-box;
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Logo = styled.div`
  img {
    width: 130px;
    height: auto;
  }
`;

export const SearchInput = styled.input`
  flex: 1;
  max-width: 300px;
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

export const UserSelector = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
`;

export const WelcomeText = styled.span`
  color: gray;
  font-size: 0.85rem;
`;

export const UserImage = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 50%;
`;
