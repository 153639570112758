import styled from "styled-components";

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 50px;
  padding: 0.65rem 1.25rem;
  min-width: 350px;
`;

export const SearchInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  flex: 1;
  color: gray; 
  padding-right: 8px;
  font-size: 13px;
  font-weight: 700;
`;

export const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray; 
`;
