import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

export const InfoLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
    margin-bottom: 45px;
`;

export const ProfileImage = styled.img`
    width: 32px; 
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
`;

export const Subtitle = styled.div`
    font-family: 'Nunito Sans';
    color: #4B4B4B;
    font-weight: 600;
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`;
