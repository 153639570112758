import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
`;

export const InfoLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const SubtitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px; /* Ajuste o espaçamento conforme necessário */
`;

export const AlertIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -20px;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #FFB84D;
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.3s;
    animation: ${pulseAnimation} .3s ease-in-out infinite;

    &:hover {
        background-color: #FFCC99;
    }
`;

export const Title = styled.div`
    font-weight: 700;
    color: #B2B2B2;
    font-size: 12px;
    font-family: 'Nunito Sans';
`;

interface SubtitleProps {
    focus?: boolean;
}

export const Subtitle = styled.div<SubtitleProps>`
    font-family: 'Nunito Sans';
    color: #4B4B4B;
    font-weight: 800;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
`;

interface LabelTagsProps {
    color: string;
    bold?: boolean;
}

export const Info = styled.span<LabelTagsProps>`
    font-size: 8px !important;
    font-weight: 700 !important;
    background-color: ${props => transparentize(0.75, props.color)};
    padding: 3px 10px;
    border-radius: 5px;
    color: ${props => props.color} !important;
    text-align: center;
`;
