import React from 'react';
import styled, { keyframes } from 'styled-components';

interface TimeBoxProps {
    color?: 'yellow' | 'green' | 'blue';
    time: string;
    showIcon?: boolean;
}

const colors = {
    green: '#00D346',
    yellow: '#FFD400',
    blue: '#1270FC',
};

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const pulse = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const IconWrapper = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #FFD700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    animation: ${pulse} 0.3s infinite;
`;

const TimeDisplay = styled.div<{ color: string }>`
    background-color: ${({ color }) => color};
    color: #fff;
    width: 70px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
`;

export const TimeBox: React.FC<TimeBoxProps> = ({ color = 'yellow', time, showIcon = false }) => {
    return (
        <Container>
            {showIcon && (
                <IconWrapper>
                    !
                </IconWrapper>
            )}
            <TimeDisplay color={colors[color]}>{time}</TimeDisplay>
        </Container>
    );
};
