import React from 'react';
import styled from 'styled-components';
import { MdCheck } from 'react-icons/md';

interface CheckIconProps {
    color?: 'green' | 'yellow' | 'blue'; // Três opções de cores
}

const colors = {
    green: '#00D346',
    yellow: '#FFD400',
    blue: '#1270FC',
};

const IconWrapper = styled.div<{ color: string }>`
    position: relative;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;

    &:hover {
        transform: scale(1.1);
        background-color: ${({ color }) => darkenColor(color)};
    }

    &:active {
        transform: scale(0.95);
    }

    &:hover::before {
        content: "Paciente pronto?";
        position: absolute;
        top: -30px; /* Espaço acima do ícone */
        font-size: 12px;
        font-weight: bold;
        color: #333;
        background-color: white;
        padding: 4px 8px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        white-space: nowrap;
    }
`;

// Função para escurecer a cor no hover
const darkenColor = (color: string): string => {
    const factor = 0.85; // Fator para escurecer
    const hexToRgb = (hex: string) => hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
    const rgbToHex = (r: number, g: number, b: number) =>
        `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
    const [r, g, b] = hexToRgb(color);
    return rgbToHex(Math.floor(r * factor), Math.floor(g * factor), Math.floor(b * factor));
};

export const CheckIcon: React.FC<CheckIconProps> = ({ color = 'green' }) => {
    return (
        <IconWrapper color={colors[color]}>
            <MdCheck size={24} />
        </IconWrapper>
    );
};
