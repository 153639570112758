import styled from "styled-components";

export const SelectWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 0.65rem;
  display: flex;
  align-items: center;
  border: 1px solid #636B74;
  position: relative; /* Necessário para o posicionamento absoluto do ícone */
`;

export const UserSelector = styled.select`
  border: none;
  background: transparent;
  outline: none;
  font-size: 13px;
  font-weight: 700;
  color: #636B74;
  padding-right: 54px; /* Espaço para evitar que o texto sobreponha o ícone */
  padding-left: 8px; /* Espaço inicial para o texto */
  appearance: none; /* Remove o estilo padrão do select */
  width: 100%; /* Garante que o select ocupe toda a largura disponível */
`;

export const DropDownIcon = styled.div`
  color: gray;
  position: absolute;
  right: 10px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
